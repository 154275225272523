.header-page-layout {
  background-color: black;
  background-image: url("../images/background.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-position: 90% 50%;
  background-size: 50%;
}

.scroll-container {
  overflow-y: scroll;
  overflow-x: hidden;
}

.two-fa-container {
  width: 12rem;
  height: 12rem;
  margin: auto;
}

.report-filter-input {
  width: 385px !important;
}

.report-date-input {
  position: relative;
  display: inline-block;
  width: 100%;
}

.report-cross-icon {
  position: absolute;
  right: 15px !important;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.report-calender-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.copy-icon {
  margin: 0;
  margin-left: 10px;
  cursor: pointer;
}

/*Hide the arrows in input type number */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*Hide the arrows in input type number */

input[type="number"] {
  -moz-appearance: textfield;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-lg {
  max-width: 710px;
}

@media screen and (max-width: 1599px) {
  .user-account-setting-btn {
    position: relative;
    top: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1600px) {
  .user-account-setting-btn {
    position: relative;
    top: 32px;
    padding-top: 13px;
    padding-bottom: 13px;
  }
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.py-1-25 {
  padding-top: 7px;
  padding-bottom: 7px;
}

.tab-icon {
  width: 17px;
  height: 14px;
}

.coin-arrow-icon {
  width: 2.8rem;
  height: 2.8rem;
  padding: 8px;
  border-width: 1px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #d3d3d3 !important;
}

.org-card {
  margin-bottom: 15px;
  padding: 10px;
}

.coin-org-card {
  margin-bottom: 30px !important;
}

.org-filter-btn {
  height: 2rem;
  width: 2rem;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-active {
  fill: #9568ff !important;
}

.tab-inactive {
  fill: #a098ae;
  color: #a098ae;
}

.plus-icon {
  height: 40px;
  width: 40px;
  padding: 0;
  border-radius: 20px;
  border-width: 1px !important;
  border-color: var(--rgba-primary-2) !important;
  // color: var(--primary) !important;
  background: var(--rgba-primary-1) !important;
}

.refresh-icon {
  height: 40px;
  width: 40px;
  padding: 0;
  border-radius: 20px;
  border-width: 1px !important;
  border-color: var(--rgba-primary-2) !important;
  background: var(--rgba-primary-1) !important;
}

.export-icon {
  height: 40px;
  width: 100px;
  padding: 0;
  border-radius: 20px;
  border-width: 1px !important;
  border-color: var(--rgba-primary-2) !important;
  background: var(--rgba-primary-1) !important;
}

.dropdown-toggle-icon::after {
  display: none !important;
}

.table-btn {
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-toggle {
  padding: 0.8rem 1.3rem;
}

.primary-card {
  height: 70px;
}

.clickable:hover {
  color: var(--title);
}
.custom-info-card {
  border-width: 0;
  border-bottom-width: 1px;
  margin-left: 5px;
  margin-right: 5px;
}
.custom-status-card {
  border-radius: 0.9rem;
  padding: 1rem;
}

.custom-status-card-container {
  min-height: 3.8rem;
  min-width: 3.8rem;
  border-radius: 1.9rem;
  background-color: rgba(255, 255, 255, 0.3);
  align-items: center;
  justify-content: center;
  display: flex;
}

.custom-status-card-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.coin-card-spinner {
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary);
}

.coin-card-image-container {
  position: absolute;
  width: 100%;
  top: -33px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coin-card-image {
  height: 75px;
  width: 85px;
}

.btc.coin-card-image {
  height: 123px;
  width: 100px;
  margin-top: -25px;
}

.dashboard-coin-card {
  position: absolute;
  width: 100%;
  top: -33px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-coin {
  height: 67px;
  width: 67px;
}

.coin-card-menu-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

.coin-card-menu {
  padding: 2px 12px;
}

.info-card {
  border-bottom: 1px solid var(--rgba-primary-2);
}

.info-bg {
  background: var(--rgba-primary-1);
}

.body-border {
  border-color: #a098ae;
}

.max-amount-button {
  position: absolute;
  top: 4px;
  right: 2px;
  z-index: 1;
  font-size: 12px;
}

.trade-activities-cont {
  height: 205px;
}

.auto-complete-cont {
  input {
    z-index: 1;
  }
  .rbt-token {
    background-color: var(--rgba-primary-1);
    color: var(--primary) !important;
  }

  .rbt-input-multi.focus {
    border-color: var(--primary) !important;
    outline: none;
    box-shadow: none;
  }
  .rbt-input-multi .rbt-input-main {
    margin: 0px 0 0px;
  }
  .rbt:not(:last-child) .form-control {
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
  }
  .rbt-token-label {
    font-size: 13px;
  }

  .rbt-input-multi.form-control {
    max-height: 100px;
    overflow-y: auto;
  }
}

.clear-auto-complete {
  position: absolute;
  right: 10px;
  top: 6px;
  z-index: 3;
}

.menu-icon-auto-complete {
  position: absolute;
  right: 20px;
  top: 13px;
  z-index: 1;
}

.input-slider {
  width: 100%;
  padding: 0px;
  accent-color: var(--primary);
}

.profile-dropdown {
  padding: 0 0 !important;
  a,
  button {
    display: none;
  }
}

.profile-dropdown.show {
  padding: 0.9375rem 0 !important;
  a,
  button {
    display: block;
  }
}

.asset-chart-body {
  min-height: 198px;
  // height: 224px;
}
.dashboard-rolling-add {
  position: absolute;
  right: 20px;
  bottom: 12px;
}
.button-width {
  padding-left: 100px;
  padding-right: 100px;
}
.font-sm {
  font-size: 12px;
}

.primary-btn-opacity {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

@media (min-width: 992px) {
  .custom-col-3 {
    flex: 0 0 auto;
    width: 29.16%;
  }

  .custom-col-4 {
    flex: 0 0 auto;
    width: 33.3%;
  }

  .custom-col-5 {
    flex: 0 0 auto;
    width: 37.5%;
  }
}

.date-picker-cont {
  flex-wrap: nowrap !important;
  .react-datepicker-wrapper {
    width: 100%;
    margin-left: -1px;
    padding-right: 1px;
    .date-picker {
      width: 100%;
      border-bottom-left-radius: 0px !important;
      border-top-left-radius: 0px !important;
    }
    .rounded-date-picker {
      width: 100%;
      border-bottom-left-radius: 0.625rem !important;
      border-top-left-radius: 0.625rem !important;
    }
  }
}

.table-coin-icon {
  height: 25px;
  width: 25px;
}

.remove-spacing {
  padding: 0px !important;
  margin: 0px !important;
}

.text-table-header {
  color: #374557 !important;
}

.no-arrow::after {
  display: none !important;
}

.dropdown-color:hover {
  color: white;
}

.coin-select-cont {
  margin-top: -24px;
}

.remaining-balance-cont {
  margin-top: -24px;
}

.asset-date {
  font-size: 12.8px;
}

.trade-recent-history-cont.dataTablehistory {
  .dataTables_wrapper {
    .dataTable {
      thead {
        tr {
          th {
            padding-top: 8px !important;
            padding-bottom: 8px !important;
            font-size: 14px !important;
            font-weight: 500 !important;
          }
        }
      }
    }
  }
}

.copy-btn {
  height: 30px;
  width: 30px;
}

.kyc-status-sidebar {
  min-height: 35px;
  min-width: 35px;
  max-height: 35px;
  max-width: 35px;
  margin-left: -6px;
  margin-right: 4px;
}

.logout-icon {
  min-height: 18px;
  min-width: 18px;
  max-height: 18px;
  max-width: 18px;
}

.kyc-danger,
#kyc-danger span {
  color: #eb5757 !important;
}

#kyc-warning span {
  color: #f0a901 !important;
}

#kyc-primary span {
  color: var(--primary) !important;
}

.footer-menu {
  // border-top: 1px solid;
  padding-top: 2px !important;
  // margin-top: 10px !important;
}

.chart-legend-cont {
  max-width: 90%;
  margin: auto;
}
.chart-legend-icon {
  width: 30px !important;
  text-align: center;
}

.btn-outline-warning.dropdown-toggle.show,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-warning.dropdown-toggle:active,
.btn-outline-danger.dropdown-toggle:active,
.btn-outline-success.dropdown-toggle.active {
  color: white;
}

.dropdown-margin-fiat {
  margin-right: 5px;
}

.main-wrapper-cont {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.any-issue-message {
  font-size: 12px;
}

.deznav {
  display: flex;
  flex-direction: column;
}

.radio-btn {
  width: 40px;
  height: 24px;
}

.z-index-3 {
  z-index: 3;
}

.min-height-220 {
  min-height: 220px;
}

.withdraw-information {
  height: 20px;
  width: 20px;
}

.user-details-cont {
  margin: -10px;
  padding: 10px;
  margin-bottom: -36px;
  padding-top: 16px;
}

.withdraw-table-header {
  background-color: #e5dff3;
}

.withdraw-table-row {
  background-color: #f4efff;
}

.input-search {
  padding-right: 35px !important;
}

.main-list-item-title {
  display: none;
}

.menu-toggle {
  .deznav-scroll.ps {
    overflow: unset !important;
  }
}

@media (min-width: 992px) {
  .menu-toggle {
    .main-list-item-title {
      display: block;
      text-align: center;
    }
    .mm-show {
      display: block;
      text-align: center;
    }
  }
}

.deznav-scroll.ps:hover > .ps__rail-x {
  opacity: 0 !important;
}

.admin-fiat-chart {
  height: 315px;
  .coin-card-spinner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.enabled-2fa {
  color: green;
}

.generate-bank-btn {
  padding: 7px 20px 7px;
}

.spinner-cont {
  padding: 2.5px 0px;
}

.reset-2fa-icon-user {
  position: relative;
  bottom: 2px;
}

.reset-2fa-icon-admin {
  position: relative;
  top: 2px;
}

.bg-primary {
  *::selection {
    background: var(--sidebar-bg);
    color: white;
  }
}

.profile-back-arrow {
  margin-right: 10px;
  transform: rotate(180deg);
  .line {
    background: var(--primary);
  }
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.resend-email-loader {
  position: relative;
  left: 1%;
  top: 2px;
}

.consent-checkbox {
  height: 22px;
  width: 22px;
}

.consent-heading {
  margin-bottom: 30px;
}
.consent-description {
  color: #374557;
  & * {
    color: #374557;
  }
}

.pagination-page {
  position: relative;
}

.pagination-page .current-page {
  position: absolute;
  right: 0px;
  font-size: 16px;
  width: fit-content;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.0625rem solid #e2e2e2;
  padding: 0.3rem 0.5rem;
  color: #715d5d;
  border-radius: 0.3125rem;
}

.pagination-page .text-label {
  margin: 0;
  margin-right: 8px;
  width: fit-content;
  text-wrap: nowrap;
}

.pagination-page .input-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-page .form-control {
  height: fit-content !important;
  line-height: unset !important;
  width: 40px;
  z-index: 99;
  opacity: 0;
}

.pagination-page .text-danger {
  display: none;
}

.terms-description {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  margin: 0px 0px 0px 10px;
}

.terms-prompt {
  font-size: 10px;
  font-weight: 600;
  line-height: 25px;
  margin: 0px 0px 0px 32px;
}

.checkbox-primary {
  height: 17px !important;
  width: 17px !important;
  border-color: var(--rgba-primary-4) !important;
}

.icon-danger {
  circle,
  path,
  svg {
    fill: $danger;
  }
}
.width-fit-content {
  width: fit-content !important;
}

.balance-edit-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  width: fit-content;
  border-radius: 50px;
  padding: 6px 12px;
  .icon {
    margin: 0px 2px 4px 0px;
  }
  &:hover {
    .icon:hover > path,
    .icon > path {
      fill: #ffffff !important;
    }
  }
}
.balance-input-cont {
  margin: 6px 0px -6px 0px;
}
.balance-edit-input {
  padding: 2px 12px 2px 26px;
  border-radius: 8px;
  background: #ffffff1a;
  border: none;
  outline: none;
  color: #fff;
}
.dollar-edit-balance {
  position: absolute;
  top: 2px;
  left: 12px;
}

.width-125px {
  width: 125px;
}

.width-250px {
  width: 250px;
}

.bulk-trade .text-danger {
  font-size: 10px;
}
.max-btn-trade {
  top: -24px;
  right: 0;
}

.input-group-withdraw-crypto .input-group > input {
  min-width: 120px;
  height: 32px !important;
  font-size: 14px;
}

.input-group-withdraw-crypto .text-danger {
  display: none;
}

table.dataTable thead th.text-transform-initial {
  text-transform: initial !important;
}

.trade-modal-cont .modal-dialog {
  max-height: 90vh;
}

.trade-modal-cont .modal-dialog,
.trade-modal-cont .modal-dialog .modal-content,
.trade-modal-cont .modal-dialog .modal-content .modal-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
}

.trade-form-body-cont {
  flex-grow: 1;
  overflow-y: auto;
}

.trade-form-table-cont {
  flex-grow: 1;
}

.filter-autocomplete-cont .text-danger {
  display: none;
}

.filter-autocomplete-cont {
  padding-top: 20px;
  position: relative;
  margin-top: -20px;
}

.filter-autocomplete-cont .form-control {
  height: 2.7rem;
}

.filter-autocomplete-cont .text-label {
  position: absolute;
  top: 0px;
  left: 0px;
}

.filter-autocomplete-cont .menu-icon-auto-complete {
  z-index: 9;
  top: 11px;
  right: 14px;
}

.currency-dropdown-menu-cont {
  min-width: fit-content;
  width: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  .menu-lis-cont {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid var(--rgba-primary-1);
    color: var(--title);
    position: absolute;
    top: 40px;
    right: -10px;
    width: fit-content;
    height: auto;
    z-index: 99;
    overflow: hidden;
    li {
      padding: 10px 15px;
      &:hover {
        background-color: var(--rgba-primary-1);
      }
    }
  }
}

.currency-icon-cont {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

.filter-input {
  width: 250px;
}

table.dataTable thead th.text-transform-initial {
  text-transform: initial !important;
}

.trade-modal-cont .modal-dialog {
  max-height: 90vh;
}

.trade-modal-cont .modal-dialog,
.trade-modal-cont .modal-dialog .modal-content,
.trade-modal-cont .modal-dialog .modal-content .modal-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
}

.trade-form-body-cont {
  flex-grow: 1;
  overflow-y: auto;
}

.trade-form-table-cont {
  flex-grow: 1;
}

.trade-form-cont {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.balance-btn-cont {
  padding: 30px 40px;
}

.crypto-buy-icon {
  color: #4eb855;
  transform: rotate(45deg);
}

.crypto-sell-icon {
  color: #da4242;
  transform: rotate(40deg);
}

.input-group > .rbt:not(:last-child) .form-control {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}
