:root {
  --nav-headbg: #ffffff;
  --sidebar-bg: #ffffff;
  // --headerbg: #C7EBFC;
  --headerbg: transparent;
}

//background for nav header
@each $name, $color in $theme_backgrounds {
  [data-nav-headerbg="#{$name}"][data-theme-version="dark"],
  [data-nav-headerbg="#{$name}"] {
    @if $name != "color_1" {
      --nav-headbg: #{$color};
      .nav-header {
        .brand-logo {
          .logo-abbr {
            .line-1 {
              fill: $white;
            }
            .line-2 {
              fill: $white;
            }
            @include respond("phone-land") {
              .line-2 {
                fill: var(--primary);
              }
            }
          }
          .brand-title {
            .line-3 {
              fill: $white;
            }
          }
        }
        .hamburger .line {
          background: $black;
        }
      }

      //special work for color
      /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
    }
  }
}
//background for nav header
@each $name, $color in $theme_backgrounds {
  [data-sidebarbg="#{$name}"][data-theme-version="dark"],
  [data-sidebarbg="#{$name}"] {
    @if $name != "color_1" {
      --sidebar-bg: #{$color};

      /* .hamburger .line{
				background:$white;
			} */

      .menu-toggle .deznav .metismenu li > ul {
        background: $color !important;
      }
      .deznav .metismenu ul a:before {
        background: rgba($white, 0.5);
      }

      &[data-layout="vertical"] {
        .deznav {
          .metismenu {
            & > li {
              &.mm-active {
                & > a {
                  i {
                    color: $white;
                  }
                }
              }
            }
          }
          .plus-box {
            h5,
            p {
              color: $white;
            }
          }
        }
      }
      &[data-sidebar-style="mini"],
      &[data-sidebar-style="modern"],
      &[data-sidebar-style="compact"],
      &[data-sidebar-style="icon-hover"][data-layout="horizontal"],
      &[data-sidebar-style="full"][data-layout="horizontal"] {
        .deznav {
          .metismenu li ul {
            background-color: lighten($color: $color, $amount: 10%);
          }
        }
        .deznav .metismenu > li.mm-active > a,
        .deznav .metismenu > li:hover > a {
          background: var(--primary);
        }
      }
      /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.deznav .metismenu > li.mm-active > a,
				.deznav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
      &[data-sidebar-style="compact"][data-layout="vertical"] {
        .deznav {
          .metismenu {
            & > li {
              a {
                &:hover {
                  color: $white;
                }
              }
              & > a {
                & > i {
                  background: lighten($color: $color, $amount: 3%);
                  color: rgba($white, 0.7);
                }
              }
              &.mm-active,
              &:hover {
                & > a {
                  box-shadow: none;
                  background: transparent !important;
                  color: $white !important;
                  i {
                    background: var(--primary);
                    color: $white !important;
                  }
                }
              }
            }
          }
        }
      }
      .deznav {
        .metismenu {
          a,
          div,
          span {
            color: rgba($white, 0.8) !important;
          }
          li {
            ul {
              a {
                &:hover,
                &:focus,
                &.mm-active {
                  color: $white;
                }
              }
            }
          }
          & > li {
            &:hover {
              & > a {
                background: rgba(255, 255, 255, 0.15) !important;
              }
            }
            & > a {
              color: rgba($white, 0.85) !important;
              i {
                color: rgba($white, 0.85) !important;
              }
            }
            &.mm-active {
              & > a {
                background: rgba(255, 255, 255, 0.15) !important;
                color: $white;
                i {
                  color: $white !important;
                }
                &:before {
                  background: $white !important;
                }
              }
            }
          }
          .has-arrow:after {
            border-color: rgba($white, 0.85)
              transparent
              transparent
              rgba($white, 0.85) !important;
          }
        }
        .header-info2 span {
          color: $white;
        }
        .sidebar-info {
          color: $white;
          i {
            color: $white;
          }
        }
      }
      .plus-box {
        background-color: lighten($color: $color, $amount: 10%);
      }
      .copyright {
        color: $white;
      }
      //special work for color
      /* &[data-sibebarbg="color_8"]{
				.deznav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .deznav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
    }
  }
}

//background for nav header
@each $name, $color in $theme_backgrounds {
  [data-headerbg="#{$name}"][data-theme-version="dark"],
  [data-headerbg="#{$name}"] {
    @if $name != "color_1" {
      --headerbg: #{$color};
      .search-area .input-group-text,
      .search-area .form-control,
      .search-area .form-control::placeholder {
        color: $white;
      }
      .input-group-text {
        svg {
          path {
            fill: $white;
          }
        }
      }
      .search-area .input-group-append .input-group-text i {
        color: $white;
      }
      .header-right {
        .search-area .form-control,
        .search-area .input-group-text {
          background-color: lighten($color: $color, $amount: 10%);
          i {
            color: $white;
          }
          svg {
            path {
              fill: $white;
            }
          }
        }
        .notification_dropdown .nav-link {
          background-color: lighten($color: $color, $amount: 10%);
          svg {
            path {
              fill: $white;
            }
          }
        }
        .dz-side-menu {
          .search-coundry {
            .dashboard-select {
              // color:$white;
              &:after {
                // border-color:$white;
              }
            }
          }
        }
      }
      .header-left {
        .dashboard_bar {
          color: $white;
        }
      }
      .header-profile > a.nav-link .header-info small,
      .header-profile > a.nav-link .header-info span {
        color: $white;
      }
      .hamburger .line {
        background: $white !important;
        svg {
          rect {
            fill: $white !important;
          }
        }
      }
      .hamburger {
        svg {
          rect {
            fill: $white !important;
          }
        }
      }
      //special work for color
      /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
    }
  }
}
//background for nav header
@each $name, $color in $theme_backgrounds {
  [data-primary="#{$name}"][data-nav-headerbg="#{$name}"][data-theme-version="light"],
  [data-nav-headerbg="#{$name}"] {
    @if $name == "color_1" {
      .nav-header {
        .brand-logo {
          .logo-abbr {
            display: none;
          }
          .brand-title {
            display: none;
          }
          .color-title {
            display: block;
          }
          .logo-color {
            display: block;
            height: 50px;
            width: 50px;
          }
          .brand-title-color {
            display: block;
            margin-left: 1rem;
            width: 10rem;
          }
        }
      }
      &[data-layout="horizontal"] {
        .header .header-content {
          padding-left: 0;
        }
      }
      &[data-sidebar-style="mini"],
      &[data-sidebar-style="modern"],
      &[data-sidebar-style="compact"] {
        .color-title {
          display: none !important;
        }
      }
      &[data-sidebar-style="icon-hover"] {
        .color-title {
          display: none !important;
        }
        .iconhover-toggle {
          .color-title {
            display: block !important;
          }
        }
      }
      &[data-sidebar-style="icon-hover"][data-layout="horizontal"] {
        .color-title {
          display: block !important;
        }
      }
      &[data-sidebar-style="morden"][data-layout="horizontal"] {
        .color-title {
          display: block !important;
        }
      }
      &[data-sidebar-style="compact"][data-layout="horizontal"] {
        .color-title {
          display: block !important;
        }
      }
      &[data-sidebar-style="compact"][data-layout="vertical"] {
        .menu-toggle {
          .color-title {
            display: block !important;
          }
        }
      }
    }
  }
}
